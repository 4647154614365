import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth';
import { getDatabase } from 'firebase/database';
import { getFunctions } from 'firebase/functions';
import moment from 'moment';
import { checkUserExists, createAccount, createUser } from './Utils/get'; // Adjust the path to your get.js file

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const provider = new GoogleAuthProvider();
const database = getDatabase(app);
const functions = getFunctions(app);

const signInWithGoogle = async () => {
  try {
    const result = await signInWithPopup(auth, provider);
    const user = result.user;
    console.log('User Info:', user);
    
    let accessToken = user.accessToken;
    let refreshToken = user.refreshToken;
    let uid = user.uid
    let email = user.email
    let displayName = user.displayName
    let expired = moment().add(23, 'hours').format('YYYY-MM-DD HH:mm:ss');

    localStorage.setItem(
      'S:User',
      JSON.stringify({
        accessToken: accessToken,
        refreshToken:refreshToken,
        expired: expired,
        uid:uid,
        email:email,
        displayName:displayName
      }),
    );

    let userExists = await checkUserExists(uid);

    if (!userExists) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.innerHTML = `gtag('event', 'conversion', {'send_to': 'AW-16707477435/fNxxCP_B2NUZELu_354-'});`;
      document.body.appendChild(script);

      const accountId = await createAccount();

      await createUser(uid, displayName, email, accountId);
      
    } else {
      console.log('User already exists');
    }

    window.location = "/";

  } catch (error) {
    console.error('Error signing in with Google:', error);
  }
};

const signOutUser = async () => {
  try {
    await signOut(auth);
    localStorage.removeItem('S:User');
    window.location.href = "/register";
    console.log('User signed out');
  } catch (error) {
    console.error('Error signing out:', error);
  }
};

export { auth, signInWithGoogle, signOutUser, database, functions };